import React from "react";

import { useNav } from "../hooks/useNav";
import { Technologies } from "./Technologies";
import img1 from "../utils/pic1.png";
import img2 from "../utils/pic2.jpg";
import img3 from "../utils/pic3.jpg";
import "./About.css";

const imagesOfMe = [img1, img2, img3];

export const About = () => {
	const aboutRef = useNav("About");

	let randomImg = imagesOfMe[Math.floor(Math.random() * imagesOfMe.length)];

	return (
		<section
			className="flex w-full h-screen overflow-y-hidden items-center"
			ref={aboutRef}
			id="aboutContainer"
		>
			<div className="flex h-full w-8/12 m-auto justify-around max-[760px]:justify-start">
				<div className="flex flex-col w-1/2 mr-2 max-[760px]:w-full">
					<div className="text-4xl text-coolGray-100">About</div>
					<div className="text-coolGray-300 text-sm w-full mt-2 pr-4">
						Welcome to my portfolio website! I'm an experienced software
						engineer who enjoys solving complex problems with innovative
						solutions. With my vast expertise in several languages &amp;
						technologies, I'm dedicated to delivering high-quality results and
						collaborating effectively with clients and team members. I invite
						you to explore my portfolio and see examples of my work. Thank you
						for visiting, and feel free to contact me with any questions or
						potential collaborations.
					</div>
					<Technologies />
				</div>
				<div className="img-gradient z-10 ml-20 mt-4 h-[310px] w-[250px] border-red-400 border max-[760px]:hidden">
					<img
						className="relative h-full picOfMe"
						src={randomImg}
						alt="pic_of_me"
					/>
				</div>
			</div>
		</section>
	);
};

export default About;

