import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Toast } from "./Toast";

export const ContactForm = () => {
	const form = useRef();
	const [isSuccess, setIsSuccess] = useState(null);

	const sendEmail = (e) => {
		setIsSuccess(null);
		e.preventDefault();

		emailjs
			.sendForm(
				"service_cd8osni",
				"template_qu5hjbu",
				form.current,
				"MsfZhcdBsXVhGyK0y"
			)
			.then(
				(result) => {
					setIsSuccess(true);
					document.getElementById("my-contact-form").reset();
				},
				(error) => {
					setIsSuccess(false);
				}
			);
	};

	return (
		<>
			<form ref={form} id="my-contact-form" onSubmit={sendEmail}>
				<div className="flex flex-col text-coolGray-100 text-sm">
					<div className="flex pb-3 mx-auto">
						<label className="pr-4">Name:</label>
						<input
							className="bg-transparent border border-b-coolGray-200  border-t-0 border-x-0 focus:outline-none"
							type="text"
							name="from_name"
						/>
					</div>
					<div className="flex pb-7 mx-auto">
						<label className="pr-4">Email:</label>
						<input
							className="bg-transparent border border-b-coolGray-200  border-t-0 border-x-0 focus:outline-none"
							type="email"
							name="user_email"
						/>
					</div>
					<div className="flex flex-col">
						<label className="pb-3 text-base font-bold">Message</label>
						<textarea
							className="min-w-[240px] bg-transparent border rounded border-coolGray-200 w-3/4 h-40 mx-auto focus:outline-none"
							name="message"
						/>
						<button
							className="bg-coolGray-100 text-coolGray-900 rounded w-fit mx-auto p-2 mt-3"
							type="submit"
						>
							Send message
						</button>
					</div>
				</div>
			</form>

			{isSuccess && <Toast.Success>Message sent</Toast.Success>}
			{isSuccess === false && (
				<Toast.Danger>Error sending message</Toast.Danger>
			)}
		</>
	);
};

export default ContactForm;
