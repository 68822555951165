import moment from "moment";

export const getDiffInDates = (mostRecentDate, leastRecentDate) => {
	const today = moment();
	const recentDate = mostRecentDate.length === 0 ? today : moment(mostRecentDate);
	return recentDate.diff(moment(leastRecentDate), "years", true);
};

export const isItFriday = () => {
	return moment().day() === 5;
};
