import React, { useState } from "react";
import { Button } from "./Button";
import { Loader } from "./Loader";
import { useNav } from "../hooks/useNav";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getDiffInDates } from "../utils/dateHelpers";
import "./Experience.css";

interface ExperienceProps {
	jobTitle: string;
	company: string;
	totalYears: number;
	stack?: string;
	achievements: string[];
}

const workExperience = [
	{
		jobTitle: "Data Developer",
		company: "Atlanta Falcons",
		totalYears: getDiffInDates([], [2024, 1]),
		stack: "sql, python, git, Azure",
		achievements: ["Data stuff"],
	},
	{
		jobTitle: "Software Developer",
		company: "Fast Radius",
		totalYears: getDiffInDates([2023, 11], [2021, 9]),
		stack: "react, typescript, elixir, Golang, postgres, AWS",
		achievements: [
			"Developed new portal for our suppliers to interact with (confirm POs, answer RFQs, track order progression and more)",
			"Implemented an entitlements and subscriptions service for our software so that we could sucsessfully implement SAAS functionality",
			"Just recently finished recurring billing for our users so that we could charge for SAAS services",
		],
	},
	{
		jobTitle: "Software Developer",
		company: "Kalderos",
		totalYears: getDiffInDates([2021, 9], [2019, 6]),
		stack: "react, typescript, C#, mysql, Azure",
		achievements: [
			"Implemented design tokens throughout or applications to ensure that styling matched in all of our products",
			"Built a chrome extension for the data science team that intercepeted file downloads and renamed them based on download inputs (saving data team hours of manually doing it)",
		],
	},
	{
		jobTitle: "Buyer",
		company: "John Crane",
		totalYears: getDiffInDates([2019, 6], [2015, 11]),
		achievements: [
			"Worked with the developement team as consultation on how the new ERP system should be modeled / built",
		],
	},
	{
		jobTitle: "Buyer",
		company: "AAR Aircraft",
		totalYears: getDiffInDates([2015, 11], [2014, 0o1]),
		achievements: [
			"Found 1.2 million in cost savings per year (over the course of 5 yrs - Delta airlines inventory contract length) through researching the best source for materials",
		],
	},
];

export const Experience = () => {
	const experienceRef = useNav("Experience");
	const [currentOpenElement, setCurrentOpenElement] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const disableButtons = isLoading;
	var classNames = require("classnames");

	const addRemoveClass = (newCurrentElement: number) => {
		setIsLoading(true);
		const currentElement = document.getElementById(
			`work-experience-${currentOpenElement}`
		);
		const newElement = document.getElementById(
			`work-experience-${newCurrentElement}`
		);
		//old
		currentElement?.classList.add("closed");
		//new
		newElement?.classList.remove("closed");
		setCurrentOpenElement(newCurrentElement);

		setTimeout(() => {
			setIsLoading(false);
		}, 900);
	};

	return (
		<section
			className="flex w-full overflow-y-hidden h-screen mt-16 max-[310px]:mt-28"
			ref={experienceRef}
			id="experienceContainer"
		>
			<div className="text-coolGray-300 w-10/12 m-auto overflow-hidden">
				<div className="h-fit">
					<div className="text-4xl pb-8 text-coolGray-100">Work Experience</div>
					{workExperience.map((experience: ExperienceProps, index: number) => {
						const isClosed = currentOpenElement !== index && "closed";
						return (
							<div
								key={experience.company}
								className={`wipe ${isClosed} bg-coolGray-700 rounded-2xl`}
							>
								<div className="p-8 min-h-[350px]">
									{isLoading ? (
										<Loader className="!relative inline-block !w-0 !h-0 !left-5" />
									) : (
										<>
											<ExperienceField
												className="!text-2xl pt-2 border border-t-0 border-x-0 border-b-coolGray-200"
												experienceLabel="Company"
												experienceValue={experience.company}
											/>
											<ExperienceField
												className="pt-1"
												experienceLabel="Title"
												experienceValue={experience.jobTitle}
											/>
											<ExperienceField
												experienceLabel="Employed Years"
												experienceValue={
													Math.round(experience.totalYears * 100) / 100
												}
											/>
											{experience.stack && (
												<ExperienceField
													experienceLabel="Stack"
													experienceValue={experience.stack}
												/>
											)}
											<div className="pt-5">Achievements:</div>
											{experience?.achievements.map((acheivement: any) => {
												return (
													<div key={acheivement}>
														<li className="text-coolGray-100 text-xs p-1">
															{acheivement}
														</li>
													</div>
												);
											})}
										</>
									)}
								</div>
							</div>
						);
					})}
				</div>

				<div className="text-center text-lg relative">
					<Button
						onClick={() => addRemoveClass(currentOpenElement - 1)}
						disabled={currentOpenElement === 0 || disableButtons}
						color="none"
						className={classNames(
							"text-coolGray-100 p-3",
							currentOpenElement === 0 ||
							(disableButtons && "!text-coolGray-500")
						)}
					>
						<Icon icon={icon({ name: "chevron-left", style: "solid" })} />
					</Button>
					<Button
						onClick={() => addRemoveClass(currentOpenElement + 1)}
						disabled={currentOpenElement === 3 || disableButtons}
						color="none"
						className={classNames(
							"text-coolGray-100 p-3",
							currentOpenElement === 3 ||
							(disableButtons && "!text-coolGray-500")
						)}
					>
						<Icon icon={icon({ name: "chevron-right", style: "solid" })} />
					</Button>
				</div>
			</div>
		</section>
	);
};

const ExperienceField = ({
	className,
	experienceLabel,
	experienceValue,
}: {
	className?: string;
	experienceLabel: string;
	experienceValue: string | number;
}) => (
	<div className={`text-lg ${className} text-coolGray-300`}>
		{experienceLabel}:&nbsp;
		<span className="text-coolGray-100">{experienceValue}</span>
	</div>
);

export default Experience;
