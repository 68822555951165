import React from "react";
import Delayed from "./Delayed";

import "./Introduction.css";

export const Introduction = () => {
	return (
		<section className="flex w-full overflow-y-hidden h-screen items-center">
			<div className="flex flex-col h-full w-8/12 m-auto justify-center">
				<Delayed>
					<div className="text-red-300 text-2xl fade-in-left">Hello,</div>
				</Delayed>
				<Delayed waitBeforeShow={600}>
					<div className="text-coolGray-200 text-6xl mb-3 fade-in-left">
						My name is Sean
					</div>
				</Delayed>
				<Delayed waitBeforeShow={900}>
					<div className="text-coolGray-300 pb-4 text-5xl mb-3 fade-in-left">
						I build software
					</div>
				</Delayed>
				<div className="h-[100px]">
					<IntroAnimation />
				</div>
			</div>
		</section>
	);
};


const IntroAnimation = () => {
	return (
		<>
			<Delayed waitBeforeShow={1200}>
				<div className="fade-in-left border border-b-red-300 border-t-0 border-x-0"></div>
			</Delayed>
			<Delayed waitBeforeShow={1500}>
				<div className="w-[90%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={1800}>
				<div className="w-[80%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={2100}>
				<div className="w-[70%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={2400}>
				<div className="w-[60%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={2700}>
				<div className="w-[50%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={3000}>
				<div className="w-[40%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={3300}>
				<div className="w-[30%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={3600}>
				<div className="w-[20%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
			<Delayed waitBeforeShow={3900}>
				<div className="w-[10%] fade-in-left border border-b-red-300 border-t-0 border-x-0 mt-3"></div>
			</Delayed>
		</>
	);
};

export default Introduction;
