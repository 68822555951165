import React from "react";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const Technologies = () => {
	return (
		<div className="flex flex-col text-coolGray-300 mt-10">
			<div className="text-coolGray-100">
				Here are some of the technologies I work with:
			</div>
			<div className="flex mt-2 ml-2 max-[440px]:flex-col">
				<div className="flex flex-col">
					<Technology dark technology="React" />
					<Technology technology="Typescript" />
					<Technology dark technology="GraphQL &amp; REST" />
					<Technology technology="Next.js" />
					<Technology dark technology="Wordpress" />
				</div>
				<div className="flex flex-col pl-16 max-[440px]:pl-0">
					<Technology technology="NodeJs" />
					<Technology dark technology="Elixir/Pheonix" />
					<Technology technology="Sql/NoSql" />
					<Technology dark technology="Javascript" />
					<Technology technology="Golang" />
				</div>
			</div>
		</div>
	);
};

const Technology = ({
	technology,
	dark,
}: {
	technology: string;
	dark?: boolean;
}) => {
	const iconClass = dark ? "text-red-300" : "text-tertiary-500";
	return (
		<div className="flex items-center">
			<Icon
				className={`${iconClass} text-xs`}
				icon={icon({ name: "chevron-left", style: "solid" })}
			/>
			<Icon
				className={`${iconClass} text-xs`}
				icon={icon({ name: "chevron-right", style: "solid" })}
			/>
			<div className="px-1 text-sm whitespace-nowrap">{technology}</div>
			<Icon
				className={`${iconClass} font-thin`}
				icon={icon({ name: "code", style: "solid" })}
			/>
		</div>
	);
};

export default Technologies;
