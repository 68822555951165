import React from "react";
import { useNav } from "../hooks/useNav";
import { Button } from "./Button";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faChampagneGlasses } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { isItFriday } from "../utils/dateHelpers";
import ContactForm from "./ContactForm";

export const Contact = () => {
	const contactRef = useNav("Contact");
	const navigate = useNavigate();
	const itsFriday = isItFriday();

	function handleOpenResume() {
		window.open(
			"https://drive.google.com/file/d/1KPj9g0WndHXCs0DA68uPZC7EGthZOvtK/view?usp=sharing"
		);
	}

	const handleFridayRedirect = () => {
		navigate("/friday");
	};

	return (
		<section
			className="flex w-full h-screen overflow-y-hidden max-[1070px]:mt-40 max-[690px]:mt-96"
			ref={contactRef}
			id="contactContainer"
		>
			<div className="h-3/4 w-8/12 m-auto text-center max-[560px]:w-full max-[690px]:mt-52">
				<div className="text-4xl text-coolGray-100">Say hello</div>
				<div className="text-lg text-coolGray-300 w-8/12 mx-auto pt-3">
					I&apos;m always willing to hear new opportunities or ideas. Drop me a
					line &amp; lets chat! &nbsp;<span className="text-red-300">:)</span>
				</div>
				<div className="flex justify-center mt-4">
					<Button
						className="text-2xl text-coolGray-100 pr-2"
						to={{ pathname: "https://www.linkedin.com/in/sean-brown88/" }}
						openInNewWindow
						color="none"
					>
						<Icon icon={faLinkedin} />
					</Button>
					<Button
						className="text-2xl text-coolGray-100 ml-2"
						to={{ pathname: "https://github.com/seanbb88" }}
						openInNewWindow
						color="none"
					>
						<Icon icon={faGithub} />
					</Button>
					{itsFriday && (
						<Button
							className="text-2xl text-coolGray-100 pl-2"
							color="none"
							onClick={handleFridayRedirect}
						>
							<Icon icon={faChampagneGlasses} />
						</Button>
					)}
				</div>

				<div className="flex flex-col mt-24 w-8/12 mx-auto">
					<a
						href="/"
						className="border text-coolGray-100 border-red-300 h-9 p-1 px-3 rounded mb-4 hover:border-coolGray-100 self-center min-[550px]:hidden"
						onClick={handleOpenResume}
					>
						Resume
					</a>
					<ContactForm />
				</div>
			</div>
		</section>
	);
};

export default Contact;
