import React from "react";
import { useNav } from "../hooks/useNav";
import { ProjectCard } from "./ProjectCard";
import fastRadiusImg from "../utils/fast-radius.png";
import kalderosImg from "../utils/kalderos.png";
import appLogo from "../utils/applogo87.png";
import reactLogo from "../utils/react.png";

const projects = [
	{
		projectName: "Fast Radius Portals",
		projectImg: fastRadiusImg,
		projectDesc: [
			"Supplier Portal",
			"Customer Portal",
			"Shared UI Repo",
			"SAAS/Billing",
		],
		buttonText: "portal link",
		projectLink: "https://os.fastradius.com/",
		projectNote:
			"Note: Account creation is required to access the use of the portal. Free sign up with minimal information required.",
	},
	{
		projectName: "Verify",
		projectImg: kalderosImg,
		projectDesc: ["Verify Portal", "Automation for DS Team"],
		buttonText: "verify desc link",
		projectLink: "https://www.kalderos.com/tools/verify",
		projectNote:
			"Note: This link is to a product description. In order to sign up for this platform you would need to be a drug manufacturer.",
	},
	{
		projectName: "Now-Playing",
		projectImg: appLogo,
		projectDesc: ["React Native", "Python server", "Postgress"],
		buttonText: "App store link",
		projectLink: "https://apps.apple.com/us/app/now-playing/id6474587326",
		projectNote:
			"Note: This application is for storing media suggestions and allowing you to pick a random item from your list as well as use ai to help find you something to watch. On the app store now!",
	},
	{
		projectName: "Design System",
		projectImg: reactLogo,
		projectDesc: ["Reusable components", "React Native/Typescript", "npm - package"],
		projectNote:
			"Note: You can find and use the components from this package via npm by searching @browntreebear/reactnativesharedui",
	},
];

export const Projects = () => {
	const projectsRef = useNav("Projects");

	return (
		<section
			className="flex w-full overflow-y-hidden h-screen"
			ref={projectsRef}
			id="projectsContainer"
		>
			<div className="text-coolGray-300 h-full w-10/12 m-auto">
				<div className="mb-8 text-4xl text-coolGray-100 pl-4 max-[690px]:text-center max-[690px]:pl-0">
					Projects
				</div>
				<div className="grid grid-cols-3 max-[1070px]:grid-cols-2 max-[690px]:grid-cols-1 max-[690px]:place-items-center">
					{projects.map((project) => (
						<ProjectCard
							key={project.projectName}
							projectName={project.projectName}
							projectImg={project.projectImg}
							projectDesc={project.projectDesc}
							projectNote={project.projectNote}
							buttonText={project.buttonText}
							projectLink={project.projectLink}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default Projects;
