import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { isItFriday } from "../utils/dateHelpers";

export const Friday = () => {
	const navigate = useNavigate();
	const isIt = isItFriday();

	useEffect(() => {
		if (!isIt) {
			return navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIt]);

	return (
		<div className="w-screen h-screen mx-auto bg-[#461046]">
			<div className="pt-40">
				<iframe
					className="mx-auto"
					width="660"
					height="415"
					src={`https://www.youtube.com/embed/OD3Tq13ZuLg?autoplay=1`}
					title="YouTube video player"
					frameBorder="0"
					allow="autoplay;"
				></iframe>
			</div>
		</div>
	);
};

export default Friday;
